/* @font-face {
  font-family: 'NimbusSanLBold';
  src: local('NimbusSanLBold'), url('./assets/fonts/NimbusSanL-Bol.ttf') format('truetype');
  font-weight: bold;
}
@font-face {
  font-family: 'NimbusSanLRegular';
  src: local('NimbusSanLRegular'), url('./assets/fonts/NimbusSanL-Reg.ttf') format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: 'GilroyBold';
  src: local('GilroyBold'), url('./assets/fonts/Gilroy-Bold.ttf') format('truetype');
  font-weight: bold;
}
@font-face {
  font-family: 'GilroyRegular';
  src: local('GilroyRegular'), url('./assets/fonts/Gilroy-Medium.ttf') format('truetype');
  font-weight: normal;
} */

body {
  margin: 0;
  font-family: 'NimbusSanLRegular', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'sans-serif' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.bold-text {
  font-family: 'GilroyRegular', 'NimbusSanLBold' !important;
}

html,
body,
#root {
  height: 100%;
  width: 100%;
}

.slider-control-bottomcenter {
  display: none;
}

.stack-chart .apexcharts-bar-series.apexcharts-plot-series .apexcharts-series path {
  clip-path: inset(0% 0% 0% -5% round 20px);
}

.custom-fieldset {
  border: 1px solid var(--chakra-colors-main-primary);
  padding: 0.35em 0.75em 0.625em;
  border-radius: 8px;
  margin-bottom: 50px;
}

.custom-legend {
  color: var(--chakra-colors-main-primary);
  padding: 0 4px;
}

/* .apexcharts-tooltip.apexcharts-theme-dark{
  background: none !important;
} */

.tooltip {
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 170px;
  height: 90px;
  padding: 8px;
  background-color: #e9f4ff;
  font-weight: 500;
  font-size: small;
  color: black;
  border: #e9f4ff;
}

.tooltip-inside {
  display: flex;
}

.tooltip-circle {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin: 7px;
  margin-top: 2.8px;
  display: inline;
}
.react-datepicker-popper {
  z-index: 9999 !important;
}

.css-hbkslp > div,
.css-1715e4s > div {
  background-color: rgb(255, 226, 52);
}

.chakra-alert > .css-njbp03{
  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
}
